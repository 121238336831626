<template>
    <div class="aa-admin">
        <div class="aa-admin-titulo aa-text-h1 text-start px-1 mt-1">
            Administración
        <div class="line"></div>
    </div>
    <BotoneraAdmin />

    <div class="clases mb-1" ref="el" v-if="listadoCursos">
        <div class="info-clases" v-for="(curso, index) in listadoCursos" :key="curso">

            <div class="aa-btn-complex">
                <div class="titulo aa-text-h2 text-start ps-1 mb-1 mt-1">
                    {{index}}
                </div>
                <div class="arrow me-2">
                    <img src="../../../assets/img/arrowR.svg" alt="Entrar">
                </div>
            </div>
            <div class="listado-clases">
                <ul class="text-start">
                    <li v-for="(clase, index) in curso" :key="index" class="mb-1">
                        {{clase.tutor.nombre}} 
                        <br>
                        <small>{{clase.curso}}</small>
                    </li>
                </ul>
            </div>
        
        </div>

    </div>


    </div>
</template>

<script>
import { onMounted, watchEffect } from '@vue/runtime-core';

import BotoneraAdmin from "../components/Administracion/BotoneraAdmin.vue"
import useAdministracion from "../composables/useAdministracion";
export default {
    components: {BotoneraAdmin},
    setup() {
        const { isLoading, listadoCursos, el, asignaAnimacion, animaEntrada } = useAdministracion() 

        watchEffect(() => {
            if (el.value) {
                asignaAnimacion()
                animaEntrada()
            }
        })

        onMounted(()=>{
            
        })

        return {
            listadoCursos,
            isLoading,
            el
        };
    }
}
</script>

<style lang="sass" scoped>
.aa-admin
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-titulo
        order: 1
        .line
            background-color: #86e4b9
            display: inline-block
            height: 20px
            left: 0
            position: absolute
            top: 37px
            width: 90%
            z-index: -1
    .clases
        order: 3
        visibility: hidden

.info-clases
    padding: 1rem
    cursor: pointer

.listado-clases
    height: 0
    overflow: hidden
    font-size: 1rem
    color: $base-color
    line-height: 145%
    ul
        list-style: none
        padding-left: 1rem !important
        li
            &::before
                content: "•"
                padding-right: 8px
                font-weight: bolder
                font-size: 2rem
                display: inline-block
                color: $green
            small
                    display: inline-block
                    padding-left: 21px

.aa-btn-complex
    small
        font-weight: 600
        color: #000

.listado-clases.expanded
    height: 0
    overflow: hidden

</style>