import { ref } from "vue"

import { gsap, Power2, Sine } from 'gsap'
import * as lodash from 'lodash'

import publicApi from "@/api/publicApi"

const useAdministracion = () => {

    const isLoading = ref(true)
    const listadoCursos = ref()
    const equipoDirectivo = ref()
    const listadoDocumentos = ref()
    const el = ref(null)
    const animations = [];

    const listadoTutores = async () => {
        try {

            const { data } = await publicApi.get('cursos/?populate[nivel][fields][0]=nombre&populate[tutor][fields][0]=nombre,email&pagination[limit]=50&sort[1]=id&filters[id][$ne]=38')
            isLoading.value = false
            if (!data) {
                return 
            }
            
            const arrayCursos = data.data.map( c => {
                return {
                    curso: c.attributes.curso,
                    nivel: c.attributes.nivel.data.attributes.nombre,
                    tutor: c.attributes.tutor.data.attributes,
                }
            })

            listadoCursos.value = lodash.groupBy(arrayCursos,'nivel')

        } catch (error) {
            console.log(error)
        }
    }

    const listadoEquipo = async() => {
        try{
            const { data } = await publicApi.get('equipo-directivos/?populate[persona][fields][0]=nombre, email, telefono&sort[0]=orden')
            isLoading.value = false
            if (!data) {
                return 
            }
            equipoDirectivo.value = data.data.map( c => {
                return {
                    id: c.id,
                    puesto: c.attributes.puesto,
                    orden: c.attributes.orden,
                    persona: c.attributes.persona.data.attributes,
                    avatar: 'https://api.kwelo.com/v1/media/identicon/' + c.attributes.puesto.replace(' ','')
                }
            })

        }catch (error) {
            isLoading.value = false
            console.log(error);
        }
    }


    const listadoDocumentacion = async() => {
        try{
            const { data } = await publicApi.get('documentacion-centros?populate[Documento][fields][0]=url&sort[0]=id:desc')
            isLoading.value = false
            if (!data) {
                return 
            }
            
            listadoDocumentos.value = data.data.map( documento => {
                return {
                    id: documento.id,
                    nombre: documento.attributes.Nombre,
                    documento: documento.attributes.Documento.data.attributes.url
                }
            })

        }catch (error) {
            isLoading.value = false
            console.log(error);
        }
    }

    const toggleAnimation = (menu) =>  {
        const selectedReversedState = menu.animation.reversed();
        animations.forEach(animation => animation.reverse());
        menu.animation.reversed(!selectedReversedState);
    }

    const createAnimation = (element) => {
        const menu = element.querySelector(".aa-btn-complex");
        const box  = element.querySelector(".listado-clases");
        gsap.set(box, { height: "auto"})
        
        const tween = gsap.from(box, { 
            height: 0, 
            duration: 0.5, 
            ease: "power1.inOut",
            reversed: true
        });
        
        menu.animation = tween;
        animations.push(tween);
    }

    const asignaAnimacion = () => {
        const groups = gsap.utils.toArray(".info-clases");
        const menus = gsap.utils.toArray(".aa-btn-complex");
        
        groups.forEach(group => createAnimation(group))
            menus.forEach(menu => {
                menu.addEventListener("click", () => toggleAnimation(menu))
            });
    }


    const animaEntrada = () => {
            gsap.timeline()
                .to('.clases', {duration: 0.4, autoAlpha:1},0)
                .from('.info-clases',{
                    x: -500,
                    alpha: 0,
                    ease: Sine.easeOut,
                    stagger: {
                        each: 0.1,
                        from: "start",
                        ease: Power2.easeOut
                    },
                    duration: 0.5
                })
    }

    listadoTutores()
    listadoEquipo()
    listadoDocumentacion()

    return {
        asignaAnimacion,
        animaEntrada,
        el,
        isLoading,
        listadoCursos,
        listadoDocumentos,
        equipoDirectivo,
    }

}

export default useAdministracion