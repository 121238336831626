<template>
    <div class="botonera mt-1">
        <div class="equipo-dir fade-in">
            <router-link :to="{ name: 'administracion'}" active-class="activo" class="d-block">
                <span class="d-block px-1 texto">Directiva</span>
                <span class="line d-block"></span>
            </router-link>
        </div>
        <div class="tutores fade-in">
            <router-link :to="{ name: 'tutores'}" active-class="activo" class="d-block">
                <span class="d-block pe-3 ms-2 texto">Tutores</span>
                <span class="line d-block"></span>
            </router-link>
        </div>
        <div class="documentacion fade-in">
            <router-link :to="{ name: 'documentacion'}" active-class="activo" class="d-block">
                <span class="d-block pe-3 ms-2 texto">
                    DOCs
                </span>
                <span class="line d-block"></span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>
.botonera
    background-color: #f1f1f1 
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
    order: 2
    height: 43px
    width: 100%
    font-size: 0.8rem
    div
        width: 30%
        position: relative
        a
            color: $base-color
            text-decoration: none
            text-transform: uppercase
            padding-top: 0.7rem
            padding-bottom: 0.7rem
            
    .equipo-dir
        a
            border-radius: 0px 15px 15px 0px
            .texto
                position: relative
                z-index: 1
        .activo
            .line
                top: 10px
                width: 95%
                height: 20px

    .documentacion
        a
            border-radius: 15px 0px 0px 15px
            .texto
                position: relative
                z-index: 1
                img
                    width: 17px
        .activo
            .line
                top: 10px
                width: 80%
                height: 20px
                right: 0px
    .tutores
        a
            .texto
                position: relative
                z-index: 1
        .activo
            .line
                top: 10px
                width: 100%
                height: 20px
                right: 0px
.activo
    background-color: rgba(0, 35, 94, 0.08)
    .line
        position: absolute
        content: " "
        background-color: #86e4b9
        z-index: 0
</style>